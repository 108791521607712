import PropTypes from 'prop-types'
import React from 'react'

import Image from './image'
import styled from 'styled-components'

const Center = styled.div`
  text-align: center;
`

const Header = ({ siteTitle }) => (
  <div
    style={{
      background: `#000000`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
          <Center>
            <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '65%'}}>
              <Image />
            </div>
          </Center>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
